const FONTS = {
  FONTSMALL: '14px',
  FONTMEDIUM: '16px',
  FONTBASE: '18px',
  FONTLARGE: '20px',
  FONTXLARGE: '26px',
  FONTXXLARGE: '32px',
  FONTBUTTONMOBILE: '22px',
  FONTBUTTON: '26px',
  FONTBUTTONLARGE: '29px',
  FONTMENUMOBILE: '28px',
  FONTMENU: '32px',
  FONTTITLE: '48px',
  FONTPAGETITLEMOBILE: '48px',
  FONTPAGETITLE: '48px',
  FONTTITLELARGE: '60px',
  FONTSUBTITLEMOBILE: '36px',
  FONTSUBTITLE: '40px',
  FONTSECTIONTITLEMOBILE: '24px',
  FONTSECTIONTITLE: '32px',
  FONTSECTIONDIVIDERMOBILE: '28px',
  FONTSECTIONDIVIDER: '34px',
  PARAGRAPHMOBILE: '22px',
  PARAGRAPHTINY: '16px',
  PARAGRAPHTINYMOBILE: '14px',
  PARAGRAPHSMALL: '18px',
  PARAGRAPHMEDIUM: '22px',
  PARAGRAPH: '24px',
  PARAGRAPHLARGE: '26px',
  PARAGRAPHXLARGE: '28px',
  PARAGRAPHGIANTMOBILE: '32px',
  PARAGRAPHGIANT: '40px',
  PARAGRAPHLARGEINTROMOBILE: '26px',
  PARAGRAPHLARGEINTRO: '30px',
  QUOTEMOBILE: '26px',
  QUOTE: '30px',
  FONTINPUTSMOBILE: '18px',
  FONTINPUTS: '22px',
  FONTLABELMOBILE: '20px',
  FONTLABEL: '26px',
  WEIGHTLIGHT: '300',
  WEIGHTREGULAR: '400',
  WEIGHTDEMI: '600',
  WEIGHTBOLD: '700',
  FAMILYPRIMARY: 'Roboto, Helvetica, Arial, sans-serif',
  FAMILYSECONDARY: 'futura-pt, sans-serif',
  FAMILYCONDENSED: 'Roboto Condensed, sans-serif',
  TITLEONEMOBILE: '42px',
  TITLEONE: '48px',
  TITLETWOMOBILE: '36px',
  TITLETWO: '40px',
  TITLETHREEMOBILE: '24px',
  TITLETHREE: '32px',
  TITLEFOURMOBILE: '22px',
  TITLEFOUR: '24px',
  TITLE_ASK_QUESTION: '36px',
}

export default FONTS
