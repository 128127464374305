import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  shallowEqual,
  useSelector,
} from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getQuoteRequestValue,
  isQuoteRequestPristine,
} from 'state/quotes/selectors'

import {
  forget as forgetSession,
  getId as getSessionId,
  start as startSession,
} from 'lib/session'

import { ForgetMeContext } from 'components/blocks/Modal/ForgetMe/context'

import { RootState } from 'state/types'

import SessionTimeout from './index.presentational'
import { ContainerProps } from './types'

const TIME_TO_LIVE_SECS = 10 * 60
const WARNING_DURATION_SECS = 30
const EVENTS_THAT_RESET_TIMER = [
  'mousemove',
  'touchstart',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]

const SessionTimeoutContainer: React.FC<ContainerProps> = ({ history }): React.ReactElement => {
  const { resetSession: _resetSession } = useContext(ForgetMeContext)

  const [
    isPristine,
    isPublicComputer,
  ] = useSelector((state: RootState) => [
    isQuoteRequestPristine(state),
    getQuoteRequestValue(state, 'isPublicComputer'),
  ], shallowEqual)

  const [
    isWarningModalOpen,
    setIsWarningModalOpen,
  ] = useState(false)

  const [
    secondsUntilExpiration,
    setSecondsUntilExpiration,
  ] = useState(TIME_TO_LIVE_SECS)

  const [
    cachedSessionId,
    setCachedSessionId,
  ] = useState('')

  const continueSession = (): void => {
    setSecondsUntilExpiration(TIME_TO_LIVE_SECS)

    setIsWarningModalOpen(false)
  }

  const resetSession = (): void => {
    setIsWarningModalOpen(false)

    setSecondsUntilExpiration(TIME_TO_LIVE_SECS)

    _resetSession()

    history.push('/quote')
  }

  const startListeningForUserActivity = (): void => {
    EVENTS_THAT_RESET_TIMER.forEach(eventName => document.addEventListener(eventName, onUserActivity))
  }

  const stopListeningForUserActivity = (): void => {
    EVENTS_THAT_RESET_TIMER.forEach(eventName => document.removeEventListener(eventName, onUserActivity))
  }

  const onUserActivity = (): void => {
    setSecondsUntilExpiration(TIME_TO_LIVE_SECS)
  }

  const onTimeout = (): void => {
    if (secondsUntilExpiration <= 0) {
      resetSession()

      return
    }

    if (secondsUntilExpiration <= WARNING_DURATION_SECS) {
      setIsWarningModalOpen(true)
      stopListeningForUserActivity()
    }

    setSecondsUntilExpiration(secondsUntilExpiration - 1)
  }

  useEffect(() => {
    let timeout: number

    if (isPublicComputer && !isPristine) {
      // Start timer
      timeout = setTimeout(onTimeout, 1000)

      if (secondsUntilExpiration > WARNING_DURATION_SECS) {
        startListeningForUserActivity()
      }
    }

    return (): void => {
      timeout && clearTimeout(timeout)

      stopListeningForUserActivity()
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    isPristine,
    isPublicComputer,
    secondsUntilExpiration,
  ])

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    window.onbeforeunload = (): boolean | void => {
      if (isPublicComputer && !isPristine) {
        return true
      }
    }

    return (): void => {
      window.onbeforeunload = null // eslint-disable-line no-null/no-null
    }
  }, [
    isPristine,
    isPublicComputer,
  ])

  // We can't know if the user clicked Cancel or Leave on beforeunload event
  // So at the beginning, if isPublicComputer is true
  // We delete the cookie (leaving the localStorage data still in memory).
  // In this way, when the browser is closed, the data is automatically cleared.
  useEffect(() => {
    const sessionId = getSessionId()
    if (isPublicComputer && sessionId) {
      setCachedSessionId(sessionId)
      forgetSession()
    }
    else if (!isPublicComputer) {
      startSession(sessionId || cachedSessionId)
    }
  }, [
    cachedSessionId,
    isPublicComputer,
  ])

  return React.createElement(SessionTimeout, {
    modalWarning: { isOpen: isWarningModalOpen },
    onContinue: continueSession,
    onReset: resetSession,
    secondsUntilExpiration,
  })
}

export default withRouter(SessionTimeoutContainer)
