import styled, {
  css,
  FlattenSimpleInterpolation,
} from 'styled-components'
import { HashLink as InternalRouterLink } from 'react-router-hash-link'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

import NewWindowBlackImg from 'assets/images/icons/new-window-black.svg'
import NewWindowWhiteImg from 'assets/images/icons/new-window-white.svg'

const linkStyles = (showExternalIcon = false): FlattenSimpleInterpolation => css`
  color: ${COLORS.BLACK};
  text-decoration: underline;

  &:link,
  &:visited {
    color: ${COLORS.BLACK};
  }

  &:hover {
    text-decoration: none;
  }

  ${showExternalIcon && `
    background-image: url(${NewWindowBlackImg});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 100% 50%;
    padding-right: 15px;
  `}
`

export const StyledExternalLinkThatLooksLikeLink = styled.a.attrs((props: { showExternalIcon: boolean }) => ({
  ...props,
  showExternalIcon: props.showExternalIcon,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${({ showExternalIcon }): FlattenSimpleInterpolation => linkStyles(showExternalIcon)}
`

export const StyledInternalLinkThatLooksLikeLink = styled(InternalRouterLink).attrs(props => ({
  ...props,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${(): FlattenSimpleInterpolation => linkStyles()}
`

const buttonStyles = (showExternalIcon = false): FlattenSimpleInterpolation => css`
  border-radius: 4px;
  color: ${COLORS.WHITE};
  display: inline-block;

  font-size: ${FONTS.FONTBUTTONMOBILE};
  &.large {
    font-size: ${FONTS.FONTBUTTONLARGE};
  }

  font-family: ${FONTS.FAMILYSECONDARY};
  font-weight: ${FONTS.WEIGHTDEMI};

  width: auto;
  max-width: auto;
  &.wide {
    width: 100%;
    max-width: 360px;
  }

  &.center {
    margin: 0 auto;
  }

  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  will-change: background-color;
  transition: background-color 100ms linear;
  &:link,
  &:visited,
  &:hover {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
  background: ${COLORS.BLUE};

  &.secondary {
    background: ${COLORS.LIGHTBLUE};
  }

  ${MEDIA.medium`
    font-size: ${FONTS.FONTBUTTON};
    &.large {
      font-size: ${FONTS.FONTBUTTONLARGE};
    }
  `}

  ${showExternalIcon && `
    background-image: url(${NewWindowWhiteImg});
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 90% 50%;
    padding-right: 90px;
  `}
`

const yellowButtonStyles = (): FlattenSimpleInterpolation => css`
  padding: 17.5px 35px;
  text-align: center;
  display: block;
  color: ${COLORS.BLACK};
  font-size: ${FONTS.FONTINPUTSMOBILE};
  font-weight: ${FONTS.WEIGHTREGULAR};
  font-family: ${FONTS.FAMILYPRIMARY};
  border: 1px solid #e0d6b6;
  border-radius: 4px;
  background: #f8f4e5;
  width: 100%;
  max-width: 460px;
  margin-top: 10px;
  margin-bottom: 10px;
  &:link,
  &:visited,
  &:hover {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
  &:hover {
    background-color: #f7eaba;
    border: 1px solid #f3dd93;
  }
  &:active,
  &:focus {
    background-color: #f3dd93;
  }
  ${MEDIA.medium`
    font-size: ${FONTS.FONTINPUTS};
  `}
`

export const StyledExternalLinkThatLooksLikeButton = styled.a.attrs((props: { showExternalIcon: boolean }) => ({
  ...props,
  showExternalIcon: props.showExternalIcon,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${({ showExternalIcon }): FlattenSimpleInterpolation => buttonStyles(showExternalIcon)}

  &.yellow {
    ${(): FlattenSimpleInterpolation => yellowButtonStyles()}
  }
`

export const StyledInternalLinkThatLooksLikeButton = styled(InternalRouterLink).attrs(props => ({
  ...props,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${(): FlattenSimpleInterpolation => buttonStyles()}

  &.yellow {
    ${(): FlattenSimpleInterpolation => yellowButtonStyles()}
  }
`

const unstyledLinkStyles = (showExternalIcon = false): FlattenSimpleInterpolation => css`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #000;
  }

  ${showExternalIcon && `
    background-image: url(${NewWindowBlackImg});
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 100% 50%;
    padding-right: 15px;
  `}
`

export const UnstyledExternalLink = styled.a.attrs((props: { showExternalIcon: boolean }) => ({
  ...props,
  showExternalIcon: props.showExternalIcon,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${({ showExternalIcon }): FlattenSimpleInterpolation => unstyledLinkStyles(showExternalIcon)}
`

export const UnstyledInternalLink = styled(InternalRouterLink).attrs(props => ({
  ...props,
  smooth: true,
}))<React.PropsWithoutRef<object>>`
  ${(): FlattenSimpleInterpolation => unstyledLinkStyles()}
`
