export enum Theme {
  button = 'button',
  default = 'default',
  unstyled = 'unstyled',
}

export interface Props {
  center?: boolean,
  isExternal?: boolean,
  secondary?: boolean,
  showExternalIcon?: boolean,
  theme?: Theme,
  to: string,
  wide?: boolean,
  yellow?: boolean,
}
