import React from 'react'
import classnames from 'classnames'

import {
  Props,
  Theme,
} from './types'

import {
  StyledExternalLinkThatLooksLikeButton,
  StyledInternalLinkThatLooksLikeButton,
  StyledExternalLinkThatLooksLikeLink,
  StyledInternalLinkThatLooksLikeLink,
  UnstyledExternalLink,
  UnstyledInternalLink,
} from './styles'

const Link: React.FC<Props> = ({
  isExternal = false,
  theme = Theme.default,
  center = false,
  secondary = false,
  showExternalIcon = undefined,
  wide = false,
  yellow = false,
  ...props
}): React.ReactElement => {
  const externalProps = (isExternal && {
    href: props.to,
    rel: 'external',
    showExternalIcon: showExternalIcon !== false,
    target: '_blank',
  }) || {}

  if (theme === Theme.button) {
    if (isExternal) {
      return (
        <StyledExternalLinkThatLooksLikeButton
          className={classnames({
            center,
            secondary,
            wide,
            yellow,
          })}
          {...externalProps}
          {...props}
        />
      )
    }

    return (
      <StyledInternalLinkThatLooksLikeButton
        className={classnames({
          center,
          secondary,
          wide,
          yellow,
        })}
        {...props}
      />
    )
  }

  if (theme === Theme.unstyled) {
    if (isExternal) {
      return <UnstyledExternalLink {...externalProps} {...props} />
    }

    return <UnstyledInternalLink {...props} />
  }

  if (isExternal) {
    return <StyledExternalLinkThatLooksLikeLink {...externalProps} {...props} />
  }

  return <StyledInternalLinkThatLooksLikeLink {...props} />
}

export * from './types'

export default Link
